const Utils = {

    splitJobDataToLinkAndDescription: function (data) {
        const sortedData = data.split("~")
        return {
            description: sortedData[0],
            link: sortedData[1]
        }
    },
    isMobile: function () {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    },
    removeNode: function (item) {
        return item.map(({ node }) => node);
    },
    getPriceOfBurger: function (burger) {
        let prices
        switch (burger) {
            case 'Slutty Beef Burger':
                prices = { two: 15, four: 30 }
                break;
            case 'Slutty Vegan Burger':
                prices = { two: 21, four: 0 }
                break;
            case 'Slutty Chick':
                prices = { two: 0, four: 27 }
                break;
            default:
                prices = { two: 15, four: 30 }
        }
        return prices
    },
    sortDataToCorrectFormat: function (data) {
        let result =

            data.map((item, idx) => {
                return { ...item, price: item.description.split('/').pop(), description: item.description.split('/').shift() }
            })

        return result

    }
}

export default Utils