import React from 'react'
import TableRow from './TableRow';
import Utils from '../../Utils/Utils';


export default function Table({ mains, sides}) {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th colSpan="2">FOODSLUT ON DELIVEROO (ALL BURGERS COME WITH FRIES)</th>
              
                </tr>
            </thead>

            <tbody>
               
                <tr colSpan="4"><td className="table-heading" colSpan="4">BURGERS</td></tr>

                    {Utils.sortDataToCorrectFormat(mains).map((item, i) => (<TableRow image={item}  key={i} name={item.title} description={item.description} price={item.price} />))}
                    <tr colSpan="4"><td className="table-heading" colSpan="4">SIDES</td></tr>

                    {Utils.sortDataToCorrectFormat(sides).map((item, i) => (<TableRow image={item}  key={i} name={item.title} description={item.description} price={item.price} />))}
                   
               

            </tbody>
        </table>
    )
}
