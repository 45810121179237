import React from "react"
import Hero from '../components/Hero';
import Layout from "../components/shared/layout"
import LayoutContent from "../components/shared/layoutContent"
// import Instagram from '../components/shared/Instagram';
import Button from '../components/BurgerHero/Button';
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import DeliverooTable from '../components/shared/DeliverooTable';
import FollowFooter from '../components/shared/FollowFooter';
import Map from '../components/Map/Map'
import Gallary from '../components/shared/Gallary';
import Img from 'gatsby-image'

let northLondon = ["Belsize Park",
"Camden",
"Primrose Hill",
"Gospel Oak",
"Hampstead",
"Swiss Cottage",
"Dartmouth Park",
"Kentish Town",
"Golders Green",
 "Chalk Farm",
"Somerstow"]

let westLondon = [
  "Notting Hill",
"Paddington",
"Bayswater",
"Kensington",
"Holland Park",
"Shepherds Bush",
"North Kensington",
"Chelsea"

]

const DeliverooPage = ({ data }) => {
  const { logo, maps,  mains, sides, dishes } = data.allContentfulDeliveroo.edges[0].node
  // const content = [childContentfulAboutPageMainCopyRichTextNode]
  return (
    <Layout>
      <SEO title="FoodSlut on Deliveroo"  url={`/deliveroo`}
 />
      <Hero
        title="FoodSlut at Deliveroo"
        techStack="We are now serving North and West London on Deliveroo"
        image={logo}
        logo={true}

      />
      <LayoutContent>


        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="tile is-vertical instructions">
              <DeliverooTable
                mains={mains}
                sides={sides}
              />
              <div className="areas__container">
              <p className="title is-4">Areas we serve</p>
                <div style={{display:"flex" , justifyContent:"start"}}>
              <ul className="areas title is-5 "> North London
                {northLondon.map((item, idx) => ( <li className="title is-6" key={idx}>{item}</li>))}
              </ul>
              <ul className="areas title is-5"> West London
                {westLondon.map((item, idx) => ( <li className="title is-6" key={idx}>{item}</li>))}
              </ul>
              </div>
              </div>
              <div className="maps__container">
    
                  {maps.map((item, idx) => (
                      <div  key={idx}  className="map">
                      <Img fluid={item.fluid} imgStyle={{
                        objectFit: "contain",

                    }} />
                    </div>
                  ))}
                      
                

              </div>
            </div>
            </div>
          <div className="tile is-4 is-vertical is-parent">
            <div className="tile is-child box">
              <div className="recipe__image-container">
                <p className="title">Order on Deliveroo</p>
                <div className="deliveroo__button-container">
                <a href="https://deliveroo.co.uk/menu/london/holland-park/foodslut-vs-mall-tavern-burger-collaboration">
                  <Button copy="Order West London" />
                </a>
                </div>
                <div className="deliveroo__button-container">
                <a href="https://deliveroo.co.uk/menu/london/hampstead/foodslut-x-mall-tavern-burger-collaboration-the-stag">
                  <Button copy="Order North London" />
                </a>
                </div>
            
                <br></br>   <br></br>
      
              </div>

            </div>
            <div className="tile is-child box">
              <div className="recipe__image-container">
                  <Map lat={51.505500} lng={-0.191670} />
                  <div className="address-container">
                    <p className="title is-5">The Mall Tavern</p>
                    <p className="title is-6">71 Palace Gardens</p>
                    <p className="title is-6">London </p>
                    <p className="title is-6">W8 4RU</p>
                  </div>

              </div>
              

            </div>
            <div className="tile is-child box">
              <div className="recipe__image-container">
                  <Map lat={51.553500} lng={-0.161550} />
                  <div className="address-container">
                    <p className="title is-5">The Stag</p>
                    <p className="title is-6">67 Fleet Rd</p>
                    <p className="title is-6">London </p>
                    <p className="title is-6"> NW3 2QU</p>
                  </div>

              </div>
              

            </div>

          </div>
        </div>
        <div className="columns is-multiline  ">
          {dishes.map((item, idx) => (<Gallary data={item} key={idx} />))}

        </div>
        <FollowFooter
          link="https://deliveroo.co.uk/menu/london/holland-park/foodslut-vs-mall-tavern-burger-collaboration"
          copy="Order on Deliveroo"
          buttonCopy="Order"

        />
      </LayoutContent>
    </Layout>
  )
}

export default DeliverooPage

export const query = graphql`
query{
    allContentfulDeliveroo {
        edges {
          node {
            id
            title
            slug
            logo {
              fluid(maxWidth: 500, maxHeight:500){
                src
              ...GatsbyContentfulFluid
            }
          }
     

          mains {
            id
            description
            title
            fluid (maxWidth: 300, maxHeight:300) {
                ...GatsbyContentfulFluid
        
            }
          }

          maps {
            id
            fluid(maxWidth: 600, maxHeight:600){
              src
            ...GatsbyContentfulFluid
          }
        }
  
          sides {
            id
            description
            title
            fluid (maxWidth: 300, maxHeight:300) {
                ...GatsbyContentfulFluid
        
            }
          }
          dishes {
            id
            fluid (maxWidth: 500, maxHeight:500) {
                ...GatsbyContentfulFluid
        
            }
          }
          }
        }
  }
}`


